/* src/components/authorPage/MainContent.module.css */

.mainContent {
    display: flex;
    flex-direction: column;
    height: 100%;
    width: 100%;
    border-radius: 12px;
    padding: 20px;
    background: #FCFCF9;
    /* Remove overflow: hidden; */
}

.scrollableContent {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}

.inputContainer {
    position: sticky;
    bottom: 0;
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: flex;
    align-items: center;

    /* Keep a max-width so it doesn’t expand uncontrollably on big screens */
    max-width: 1200px;

    /* Let it center horizontally and shrink on smaller screens */
    width: 100%;
    margin: 0 auto;
    padding: 0 16px; /* Some padding for smaller screens */
    box-sizing: border-box;
}

.inputField {
    margin-left: 20px;
    padding-top: 6px;
    flex-grow: 1;
    border: none;
    font-family: 'Crimson Pro', serif;
    font-size: 20px;
    color: #1E3742;
    outline: none;
    resize: none; /* Prevent manual resizing */
    overflow-y: auto; /* Show scrollbar when content exceeds max height */
    max-height: calc(20px * 4 + 24px); /* Max height for 4 lines */
    line-height: 20px; /* Set line-height to calculate height correctly */
}

.toggleContainer {
    display: flex;
    align-items: center;
    margin: 0 15px;
}

.toggleSwitch {
    appearance: none;
    width: 40px;
    height: 20px;
    background: #d3d8d9;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
}

.toggleSwitch:checked {
    background: #21808D;
}

.toggleSwitch:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggleSwitch:checked:before {
    transform: translateX(20px);
}

.toggleLabel {
    margin-left: 8px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #21808D;
}

.sendButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.3s;
}

.sendButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.sendArrowIcon {
    width: 40px;
    height: 40px;
    color: #21808D;
}

/* Accessibility enhancements */
.sendButton:focus, .toggleSwitch:focus {
    outline: 2px solid #21808D;
}


@media (max-width: 768px) {
    .mainContent {
        padding: 0;
        background: #F9FAF7;
    }

    .inputContainer {
        bottom: 8px;
    }

    .toggleContainer {
        display: none;
    }
}