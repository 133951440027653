.formContainer {
    /* On large screens: fixed max-width and centered */
    position: absolute;
    bottom: 309px;
    width: 100%;
    max-width: 641px;
    margin: 0 auto; /* Centers the form horizontally */
    display: inline-flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    padding: 48px 24px 40px 24px;
    border-radius: 68px;
    background: rgba(248, 248, 247, 0.23);
    backdrop-filter: blur(24.75px);
    box-sizing: border-box;
}

/* On smaller screens, let it flow naturally and scale down automatically */
@media (max-width: 992px) {
    .formContainer {
        position: static;
        bottom: auto;
        width: 90%;
        max-width: 641px;
        margin: 0 auto;
        padding: 17px 0 0;
        border-radius: 32px;
        border: 0 solid #F2F3EE;
        background: rgba(248, 248, 247, 0.57);
        box-shadow: 0 12px 21.7px 0 rgba(0, 0, 0, 0.10);
        backdrop-filter: blur(24.75px);
    }
}

/* Even smaller screens */
@media (max-width: 600px) {
    .formContainer {

    }
}

.formContentContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 24px;
}

@media (max-width: 600px) {
    .formContentContainer {

    }
}

.formTopSection {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.formTitle {
    font-family: 'Spectral', serif;
    font-style: italic;
    font-weight: 300;
    font-size: 80px;
    line-height: 99.2%;
    text-align: center;
    letter-spacing: -0.05em;
    color: #1E3842;
    margin: 0;
}

/* Adjust title size for smaller screens */
@media (max-width: 992px) {
    .formTitle {
        font-size: 56px;
    }
}

@media (max-width: 600px) {

}

.formSubTitle {
    max-width: 381px;
    font-family: 'Hanken Grotesk', serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 26px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1E3742;
    opacity: 0.7;
    margin: 0 auto;
}

@media (max-width: 992px) {
    .formSubTitle {
        max-width: 320px;
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .formSubTitle {
        max-width: 280px;
    }
}

.formFieldsContainer {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    /*gap: 28px;*/
    padding-top: 24px;
    padding-bottom: 28px;
    box-sizing: border-box;
    width: 100%;
    max-width: 472px;
    background: none;
}

@media (max-width: 992px) {
    .formFieldsContainer {
        padding: 20px;
        /*gap: 20px;*/
        max-width: 100%;
    }
}

@media (max-width: 600px) {
    .formFieldsContainer {
        padding: 16px;
        /*gap: 16px;*/
    }
}

.formField {
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding: 20px 26px;
    gap: 10px;
    width: 100%;
    background: #FCFCF9;
    box-shadow: 0 4px 20px rgba(30, 55, 66, 0.07);
    border-radius: 60px;
    position: relative;
}

@media (max-width: 992px) {
    .formField {
        border-radius: 40px;
    }
}

@media (max-width: 600px) {
    .formField {
        border-radius: 30px;
    }
}

.inputField {
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    font-family: 'Hanken Grotesk', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #1E3742;
    opacity: 0.9;
}

@media (max-width: 600px) {
    .inputField {
        font-size: 16px;
    }
}

.inputField::placeholder {
    color: #1E3742;
    opacity: 0.5;
}

.inputField.errorInput {
    color: #E63946;
}

.inputField.errorInput::placeholder {
    color: #E63946;
}

.formAcknowledgeContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 12px;
    width: 100%;
    box-sizing: border-box;
}

@media (max-width: 600px) {
    .formAcknowledgeContainer {
        flex-direction: row;
        align-items: center;
        gap: 12px;
    }
}

/* Checkbox */
.formAckCheckBox {
    width: 20px;
    height: 20px;
    border: 2px solid #1E3742;
    border-radius: 4px;
    background: transparent;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease;
}

@media (max-width: 600px) {
    .formAckCheckBox {
        width: 25px;
        height: 20px;
    }

}

.formAckCheckBox.checked {
    border: 2px solid transparent;
    background: #1E3742;
}

.checkIcon {
    width: 16px;
    height: 16px;
    fill: #ffffff;
    transition: all 0.2s ease;
}

.formAckText {
    font-family: 'Hanken Grotesk', serif;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #1E3742;
    opacity: 0.7;
}

@media (max-width: 600px) {
    .formAckText {
        font-size: 12px;
        line-height: 16px;
    }
}

.link {
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 13px;
    font-weight: 700;
    line-height: 17px;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    transition: color 0.2s ease;
}

.link:hover {
    color: #007bff;
}

.formSubmitButton {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 12px;
    gap: 10px;
    width: 100%;
    height: 73px;
    background: #1E3842;
    box-shadow: 0 4px 20px rgba(30, 55, 66, 0.07);
    border-radius: 51px;
    font-family: 'Spectral', serif;
    font-weight: 500;
    font-size: 25px;
    line-height: 38px;
    letter-spacing: -0.02em;
    color: #FFFFFF;
    cursor: pointer;
    transition: transform 0.2s ease, box-shadow 0.2s ease;
}

@media (max-width: 992px) {
    .formSubmitButton {
        font-size: 22px;
        padding: 10px;
        border-radius: 40px;
    }
}

@media (max-width: 600px) {
    .formSubmitButton {
        font-size: 20px;
        padding: 8px;
        border-radius: 30px;
    }
}

.formSubmitButton:hover:not(.disabledButton) {
    transform: translateY(-3px);
    box-shadow: 0 8px 24px rgba(30, 55, 66, 0.15);
}

.disabledButton {
    background: #8e8e8e;
    cursor: not-allowed;
    pointer-events: none;
}

.errorText {
    margin-top: 6px;
    margin-bottom: 6px;
    padding-left: 30px;
    font-family: 'Hanken Grotesk', serif;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    color: #E63946;
    animation: fadeIn 0.2s ease-in-out;
}

@media (max-width: 992px) {
    .errorText {
        margin-top: 6px;
        margin-bottom: 6px;
    }
}

@media (max-width: 600px) {
    .errorText {
        margin-top: 2px;
        margin-bottom: 2px;
    }
}

.emptyError {
    height: 28px;
}

@media (max-width: 992px) {
    .emptyError {
        height: 22px;
    }
}

@media (max-width: 600px) {
    .emptyError {
        height: 20px;
    }
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(-5px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}
