/* src/components/authorPage/AuthorPage.module.css */

.authorPage {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100vh;
    background-color: #F2F3EE;
}

.content {
    display: flex;
    padding: 20px;
    gap: 20px;
    height: calc(100vh - 70px)
}


@media (max-width: 768px) {
    .authorPage {
        background-color: #F9FAF7;
    }
    .content {
        background-color: #F9FAF7;
        margin-top: 24px;
        flex-direction: column;
        height: auto; /* Let height auto-expand for stacked layout */
    }
}