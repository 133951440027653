.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(30, 55, 66, 0.8); /* Semi-transparent background */
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal {
    background-color: #ffffff;
    padding: 24px;
    border-radius: 12px;
    width: 100%;
    max-width: 500px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.modal h2 {
    margin-top: 0;
    font-size: 24px;
    font-weight: bold;
    color: #1e3742;
    margin-bottom: 16px;
}

.modal form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

.modal label {
    display: flex;
    flex-direction: column;
    font-size: 14px;
    color: #1e3742;
}

.modal input,
.modal textarea,
.modal select {
    padding: 8px;
    border: 1px solid #d3d8d9;
    border-radius: 8px;
    font-size: 14px;
}

.modal input:focus,
.modal textarea:focus,
.modal select:focus {
    outline: none;
    border-color: #21808d;
}

.actions {
    display: flex;
    justify-content: flex-end;
    gap: 12px;
    margin-top: 16px;
}

.actions button {
    padding: 8px 16px;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
}

.actions button[type='submit'] {
    background-color: #21808d;
    color: #ffffff;
}

.actions button[type='submit']:hover {
    background-color: #1e6b78;
}

.actions button[type='button'] {
    background-color: #f1f4f5;
    color: #1e3742;
}

.actions button[type='button']:hover {
    background-color: #d3d8d9;
}

.bookId {
    font-size: 14px;
    color: #1e3742;
    margin-bottom: 16px;
    background-color: #f9fafb;
    padding: 8px 12px;
    border-radius: 8px;
}
