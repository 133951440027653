/* client/src/components/auth/LoginPage.module.css */
.authPage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #F2F3EE; /* Matches the background of AuthorPage */
    font-family: 'Hanken Grotesk', sans-serif;
    padding: 20px;
}

.logoContainer {
    margin-bottom: 30px;
}

.logo {
    width: 120px;
    height: auto;
}

.authForm {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 400px;
    padding: 30px;
    background-color: white;
    border: 1px solid #D3D8D9;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.title {
    font-family: 'Crimson Pro', serif;
    font-size: 24px;
    color: #1E3742;
    text-align: center;
    margin-bottom: 10px;
}

.subtitle {
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 16px;
    color: #6F6F6F;
    text-align: center;
    margin-bottom: 20px;
}

.label {
    margin-bottom: 15px;
    font-size: 14px;
    color: #1E3742;
    display: flex;
    flex-direction: column;
}

.input {
    padding: 12px;
    font-size: 14px;
    border: 1px solid #D3D8D9;
    border-radius: 6px;
    margin-top: 5px;
    outline: none;
    font-family: 'Hanken Grotesk', sans-serif;
}

.input:focus {
    border-color: #21808D;
    box-shadow: 0 0 5px rgba(33, 128, 141, 0.3);
}

.button {
    padding: 12px;
    font-size: 16px;
    height: 48px;
    background-color: #21808D;
    border: none;
    color: white;
    cursor: pointer;
    margin-top: 20px;
    border-radius: 8px;
    font-family: 'Hanken Grotesk', sans-serif;
    transition: background-color 0.3s ease;
}

.button:hover {
    background-color: #18636E;
}

.secondaryButton {
    padding: 10px 12px;
    font-size: 14px;
    color: #21808D;
    background: none;
    border: none;
    cursor: pointer;
    margin-top: 10px;
    font-family: 'Hanken Grotesk', sans-serif;
    align-self: center; /* Center the button */
    transition: color 0.3s ease;
}

.secondaryButton:hover {
    color: #18636E;
}

.errorMessage {
    color: red;
    font-size: 14px;
    text-align: center;
    margin-top: 15px;
}