/* ProfileSectionMini.module.css */

.profileMobile {
    display: flex;
    flex-direction: column;
    gap: 12px;
    width: 100%;
    max-width: 335px;
    margin: 0 auto;
    box-sizing: border-box;
}

.topRow {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 16px; /* instead of 149px from Figma, which is huge */
    width: 100%;
}

.authorAvatar {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    object-fit: cover;
}

.textContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 4px;
}

.authorGreetings {
    font-family: 'Crimson Pro', serif;
    font-size: 25px;
    line-height: 28px;
    letter-spacing: -0.03em;
    font-weight: 400;
    color: #1E3742;
}

.authorSubtitle {
    font-family: 'Crimson Pro', serif;
    font-size: 25px;
    line-height: 28px;
    font-weight: 400;
    letter-spacing: -0.03em;
    color: #1E3742;
    opacity: 0.5;
}
