/* Steps Container */
.stepsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center; /* Centers the steps horizontally on large screens */
    align-items: flex-start;
    gap: 24px;
    /* Remove fixed height to allow content to flow */
    /* height: 444px; */
    padding: 0;
    margin: 0 auto;
    box-sizing: border-box;
}

/* Step Card Container */
.stepCard {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px; /* Base width for large screens */
    gap: 35px;
    padding-bottom: 50px;
    background: #FCFCF9;
    box-shadow: 0 2px 3px rgba(30, 55, 66, 0.16), 0 2px 4px rgba(30, 55, 66, 0.06);
    border-radius: 12px;
    position: relative;
    box-sizing: border-box;
}

/* Step Image */
.stepImage {
    margin-top: 7px;
    width: 321px;
    height: auto;
}

/* Step Badge */
.stepBadge {
    position: absolute;
    top: -25px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 12px 16px;
    height: 49px;
    background: rgba(252, 252, 249, 0.7);
    border: 2px solid rgba(221, 221, 210, 0.28);
    box-shadow: 0 8.82222px 24.9228px rgba(0, 0, 0, 0.07);
    border-radius: 14px;
    font-family: 'Spectral', serif;
    font-style: italic;
    font-weight: 400;
    font-size: 25px;
    line-height: 100%;
    text-align: center;
    letter-spacing: -0.5px;
    color: #1e3742;
    z-index: 1;
}

/* Text Container */
.textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    width: 272px;
    text-align: center;
    z-index: 2;
    padding-right: 20px;
    padding-left: 20px;
}

/* Step Title */
.stepTitle {
    font-family: 'Spectral', serif;
    font-style: italic;
    font-weight: 600;
    font-size: 22px;
    line-height: 99.2%;
    color: #1e3842;
    margin: 0;
}

/* Step Description */
.stepDescription {
    font-family: 'Spectral', serif;
    font-size: 22px;
    font-style: normal;
    font-weight: 300;
    line-height: 99.2%;
    letter-spacing: -0.88px;
    color: #1E3842;
}

/* ----------------- Media Queries for Responsiveness ----------------- */

/* Slightly smaller on medium desktops/laptops */
@media (max-width: 1200px) {
    .stepCard {
        width: 320px;
    }
    .stepImage {
        width: 280px;
    }
    .stepTitle {
        font-size: 20px;
    }
    .stepDescription {
        font-size: 20px;
    }
}

/* On smaller tablets, reduce size further */
@media (max-width: 992px) {
    .stepCard {
        width: 280px;
    }
    .stepImage {
        width: 240px;
    }
    .stepTitle {
        font-size: 18px;
    }
    .stepDescription {
        font-size: 18px;
    }
}

/* On tablets and large phones: stack cards vertically */
@media (max-width: 853px) {
    .stepsContainer {
        gap: 45px;
        flex-direction: column;
        align-items: center;
    }
    .stepCard {
        width: 100%;
        /*max-width: 300px;*/
    }
    .stepImage {
        width: 100%;
    }
    .stepTitle {
        font-size: 22px;
    }
    .stepDescription {
        font-size: 22px;
    }
}

/* On very small screens: even more compact */
@media (max-width: 480px) {
    .stepCard {
        width: 100%;
    }
    .stepTitle {
        font-size:  22px;
    }
    .stepDescription {
        font-size:  22px;
    }
}
