.sidebar {
    width: 240px;
    background-color: #1e3742; /* Dark primary background */
    color: #ffffff; /* Text on dark background */
    display: flex;
    flex-direction: column;
    padding: 16px;
    gap: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.logo {
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    text-align: center;
    margin-bottom: 20px;
}

.menu {
    list-style: none;
    padding: 0;
    margin: 0;
}

.menu li {
    margin-bottom: 10px;
}

.link {
    display: block;
    color: #d3d8d9;
    text-decoration: none;
    font-size: 16px;
    padding: 10px 16px;
    border-radius: 8px;
    transition: background-color 0.3s, color 0.3s;
}

.link:hover {
    background-color: #21808d;
    color: #ffffff;
}

.activeLink {
    text-decoration: none;
    display: block;
    background-color: #21808d;
    color: #ffffff;
    border-radius: 8px;
    font-weight: bold;

    padding: 10px 16px;
}

@media (max-width: 768px) {
    .sidebar {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        padding: 8px;
    }

    .menu {
        display: flex;
        gap: 12px;
    }

    .menu li {
        margin-bottom: 0;
    }
}
