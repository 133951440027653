/* src/components/authorPage/Learnings.module.css */

.learnings {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.card {
    display: flex;
    width: 260px;
    padding: 20px;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 14px;
    border-radius: 12px;
    border: 1px solid #E3E5DC;
    background: linear-gradient(0deg, #EDF2F1 0%, #EDF2F1 100%), linear-gradient(180deg, #F2F3EE 0%, #E5E6E0 100%);
    box-shadow: 0px 4px 8px 0px rgba(30, 55, 66, 0.03), 0px 2px 4px 0px rgba(30, 55, 66, 0.03);
}

.quote {
    flex: 1 0 0;
    color: #1E3742;
    font-family: "Crimson Pro", serif;
    font-size: 16px;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.16px;
}

.divider {
    align-self: stretch;
    /* No additional styles needed if the SVG is sized correctly */
}

.cardFooter {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
}

.likes {
    display: flex;
    align-items: center;
    gap: 5px;
}

.likesNumber {
    color: #6C7C83;
    font-family: "Hanken Grotesk", sans-serif;
    font-size: 13px;
    font-weight: 400;
    line-height: normal;
}

.heartIcon,
.addToFavoritesIcon {
    width: 16px;
    height: 16px;
}

/* Optional: Adjust SVG fill colors if necessary */
.heartIcon path,
.addToFavoritesIcon path {
    fill: #6C7C83;
}


@media (max-width: 768px) {
    .divider {
        display: none;
    }

    .card {
        width: 100%;
    }
}