/* src/components/authorEdit/AuthorDashboard.module.css */
.dashboard {
    display: flex;
    height: 100vh;
}

.nav {
    width: 200px;
    background-color: var(--color-0);
    display: flex;
    flex-direction: column;
}

.nav a {
    color: white;
    padding: 15px;
    text-decoration: none;
}

.nav a:hover {
    background-color: var(--color-1);
}

.active {
    background-color: var(--color-2);
}

.content {
    flex: 1;
    padding: 20px;
}
