.bookListContainer {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.addButton {
    background-color: #21808d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.addButton:hover {
    background-color: #1e6b78;
}

.bookTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.bookTable th,
.bookTable td {
    padding: 8px 12px;
    border: 1px solid #e3e5dc;
    text-align: left;
    min-width: 160px;
}

.bookTable th {
    background-color: #f9faf7;
    font-weight: bold;
}

.bookCover {
    width: 50px;
    height: 70px;
    object-fit: cover;
    cursor: pointer;
}

.editButton,
.deleteButton {
    background-color: #f1f4f5;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
}

.editButton:hover {
    background-color: #d3d8d9;
}

.deleteButton:hover {
    background-color: #d9534f;
    color: white;
}

.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 8px;
}

.pagination button {
    padding: 8px 16px;
    border: none;
    background-color: #f1f4f5;
    cursor: pointer;
    border-radius: 4px;
}

.pagination button:hover {
    background-color: #d3d8d9;
}

.pagination span {
    font-size: 14px;
    color: #1e3742;
}
