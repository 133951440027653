/* Loading.module.css */

/* Main Container */
.loadingContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; /* Center vertically and stack spinner and text */
    width: 100%;
    height: 100%;
    position: relative;
}

.fullscreen {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 9999;
}

/* Spinner Loader */
.spinner {
    width: 40px;
    height: 40px;
    border: 4px solid #d3d8d9;
    border-top-color: #21808D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 16px; /* Space between spinner and message */
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

/* Dots Loader */
.dotsLoader {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.dot {
    width: 10px;
    height: 10px;
    background-color: #21808D;
    border-radius: 50%;
    animation: bounce 1.2s infinite ease-in-out;
}

.dot:nth-child(1) {
    animation-delay: 0s;
}

.dot:nth-child(2) {
    animation-delay: 0.2s;
}

.dot:nth-child(3) {
    animation-delay: 0.4s;
}

@keyframes bounce {
    0%, 80%, 100% {
        transform: scale(0);
    }
    40% {
        transform: scale(1);
    }
}

/* Skeleton Loader */
.skeletonLoader {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
}

.skeleton {
    background-color: #DDE2E4;
    height: 18px;
    border-radius: 4px;
    animation: pulse 1.5s infinite ease-in-out;
}

.skeleton.wide {
    width: 80%;
}

.skeleton.medium {
    width: 60%;
}

.skeleton.narrow {
    width: 40%;
}

@keyframes pulse {
    0% {
        opacity: 1;
    }
    50% {
        opacity: 0.5;
    }
    100% {
        opacity: 1;
    }
}

/* Loader Message Text */
.message {
    margin-top: 24px; /* Space between dots and message */
    font-family: 'Crimson Pro', serif;
    font-size: 18px;
    line-height: 1.5;
    text-align: center;
    color: #1E3742;
    padding: 0 16px; /* Padding for small screens */
    width: 100%;
    max-width: 400px; /* Prevents message from being too wide */
}
