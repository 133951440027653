/* src/components/common/Header.module.css */

.header {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    justify-content: space-between;
    max-height: 71px;
    width: 100%;
}

.logoContainer {
    display: flex;
    align-items: center;
    cursor: pointer;
}

.logo {
    width: auto;
}

.buttonGroup {
    display: flex;
    gap: 25px;
}

.headerButton {
    font-family: "Crimson Pro", serif;
    font-size: 18px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    opacity: 0.7;
    letter-spacing: -0.54px;
    color: #1E3742;
    cursor: pointer;
    user-select: none;
    transition: opacity 0.3s;
}

.headerButton:hover {
    opacity: 1;
}

/* Mobile styles */
.mobileMenuContainer {
    display: flex;
    align-items: center;
}

.mobileMenuButton {
    background: none;
    color: #1E3742;
    border: none;
    cursor: pointer;
    margin-top: 12px;
}

.mobileMenuButton img {
    width: 24px; /* Adjust as needed */
    height: auto;
}

.mobileMenu {
    position: absolute;
    top: 48px; /* Adjust based on header height */
    right: 12px;
    background-color: #F9FAF7; /* Match background or use a suitable color */
    display: flex;
    flex-direction: column;
    padding: 10px;
    border-radius: 8px;
    border-left: 1px solid #ddd;
    border-right: 1px solid #ddd;
    border-bottom: 1px solid #ddd;
    z-index: 10;
    gap: 15px;
    width: 150px;
    align-items: flex-start;
}

/* Hide button group on mobile */
@media (max-width: 768px) {
    .buttonGroup {
        display: none;
    }

    .logo {
        padding-left: 0; /* Adjust logo padding on mobile if needed */
    }
}