.userListContainer {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.createButton {
    background-color: #21808d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.createButton:hover {
    background-color: #1e6b78;
}

.userTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.userTable th,
.userTable td {
    padding: 8px 12px;
    border: 1px solid #e3e5dc;
}

.userTable th {
    background-color: #f9faf7;
    text-align: left;
}

.editButton,
.deleteButton {
    background-color: #f1f4f5;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
}

.editButton:hover {
    background-color: #d3d8d9;
}

.deleteButton:hover {
    background-color: #d9534f;
    color: white;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
}

.pagination button {
    padding: 8px 12px;
    border: 1px solid #d3d8d9;
    background-color: white;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
