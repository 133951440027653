.publicClosed {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100vh;
    text-align: center;
    background-color: #F2F3EE; /* Matches the background of AuthorPage */
    padding: 20px;
}

.logo {
    width: 150px;
    height: auto;
    margin-bottom: 20px;
}

.title {
    font-family: 'Crimson Pro', serif;
    font-size: 36px;
    color: #1E3742;
    margin-bottom: 20px;
}

.message {
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 18px;
    color: #6F6F6F;
    margin-bottom: 20px;
    max-width: 600px;
    line-height: 1.6;
}

.message a {
    color: #1E3742;
    text-decoration: none;
    font-weight: 600;
}

.message a:hover {
    text-decoration: underline;
}

.divider {
    margin: 60px auto 0;
    width: 515px;
    height: 0;
    border: 1px solid #21808D;
}

.footer {
    margin-top: 34px;
    width: 462px;
    height: 27px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 15px;
    line-height: 20px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #1E3742;
    opacity: 0.7;
}

.buttonsContainer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 20px;
    margin-top: 20px;
}

.loginButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    position: relative;
    width: 148px;
    height: 47px;
    background: #1E3842;
    border-radius: 35px;
    font-family: 'Hanken Grotesk', serif;
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 23px;
    letter-spacing: -0.02em;
    color: #F3F3EE;
    cursor: pointer;
    border: none; /* Add this to remove any default border */
}

.loginButton:hover {
    background-color: #21808D; /* Example - darken the color */
    /* You could also add a subtle box-shadow */
}

@media (max-width: 768px) {
    .divider {
        width: 300px;
    }
}
