/* ChatComponent.module.css */

.chatContainer {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-height: 0;

    /* Keep the responsive approach */
    max-width: 1200px;    /* Keep a max to prevent expanding too wide */
    width: 100%;          /* Let it shrink below 1200px */
    margin: 0 auto;       /* Center horizontally */
    padding: 0 16px;      /* A bit of padding for smaller screens */
    box-sizing: border-box;
    overflow-x: hidden;   /* Hide any accidental horizontal overflow */
}

.messageList {
    flex-grow: 1;
    overflow-y: auto;
    padding: 10px;
    min-height: 0;
}

.userMessage {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 10px;
    gap: 10px;
}

.userMessageBubble {
    background-color: #F2F3EE;
    padding: 10px 20px;
    border-radius: 18px 18px 18px 0;
    max-width: 55%;
    text-align: left;
    font-family: 'Crimson Pro', serif;
    word-wrap: break-word;
    white-space: pre-wrap;
    font-size: 16px;
    line-height: 1.5;
    color: #1E3742;
    display: flex;
    align-items: center;
}

.userAvatar {
    width: 32px;
    height: 32px;
    border-radius: 50%;
    margin-left: 8px;
}

.assistantMessage {
    display: flex;
    align-items: flex-start;
    margin-bottom: 20px;
    flex-direction: column;
}

.assistantMessageAndPhoto {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    margin-right: 48px; /* Ensure right margin for proper alignment */
}

.assistantAvatar {
    width: 40px;
    height: 40px;
    margin-top: 20px;
    border-radius: 50%;
    margin-right: 16px;
}

.assistantMessageBubble {
    padding: 12px;
    text-align: left;
    font-family: 'Crimson Pro', serif;
    font-size: 16px;
    line-height: 1.5;
    color: #1E3742;
    display: block;
    border-radius: 12px;
}

@media (max-width: 768px) {
    .assistantMessageBubble {
        font-family: Crimson Text, serif;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
        letter-spacing: -0.02rem;
    }

    .userMessageBubble {
        font-family: "Hanken Grotesk", sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 18px;
    }
}

.assistantActions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-left: 66px;
    color: #A3ADB0
}

.voiceLoader {
    width: 24px;
    height: 24px;
    border: 3px solid #d3d8d9;
    border-top-color: #21808D;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.voiceIcon, .pauseIcon {
    width: 24px; /* Fixed width for both icons */
    height: 24px; /* Fixed height for both icons */
    display: flex; /* Use flexbox for centering */
    justify-content: center; /* Horizontally center the SVG */
    align-items: center; /* Vertically center the SVG */
    cursor: pointer; /* Make it clickable */
    color: #A3ADB0
}

.dotsIcon {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.inputContainer {
    margin-top: auto; /* Pushes the inputContainer to the bottom */
    margin-bottom: 4px; /* Adds 4px margin between input and bottom */
    background-color: #ffffff;
    border-radius: 30px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    z-index: 10;
    display: flex;
    align-items: center;
    padding: 12px 4px;
    flex-shrink: 0; /* Prevents shrinking */
}

.inputField {
    padding: 12px 18px;
    flex-grow: 1;
    border: none;
    font-family: 'Crimson Pro', serif;
    font-style: normal;
    line-height: normal;
    font-size: 20px;
    color: #1E3742;
    outline: none;
}

.toggleContainer {
    display: flex;
    align-items: center;
    margin: 0 15px;
}

.toggleSwitch {
    appearance: none;
    width: 40px;
    height: 20px;
    background: #d3d8d9;
    border-radius: 20px;
    position: relative;
    cursor: pointer;
    outline: none;
    transition: background-color 0.3s;
}

.toggleSwitch:checked {
    background: #21808D;
}

.toggleSwitch:before {
    content: "";
    position: absolute;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background: #ffffff;
    border-radius: 50%;
    transition: transform 0.3s;
}

.toggleSwitch:checked:before {
    transform: translateX(20px);
}

.toggleLabel {
    margin-left: 8px;
    font-family: 'Hanken Grotesk', sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: #21808D;
}

.sendButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 10px;
    transition: transform 0.3s;
}

.sendButton:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}

.sendArrowIcon {
    width: 40px;
    height: 40px;
    color: #21808D;
}

/* Accessibility enhancements */
.sendButton:focus, .toggleSwitch:focus {
    outline: 2px solid #21808D;
}

@media (max-width: 768px) {
    .chatContainer {
        padding: 0;
    }

    .messageList {
        padding: 0;
    }
}
