.overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure it's on top */
}

.modal {
    background-color: var(--color-8); /* White background */
    width: 70%; /* 70% of screen width */
    max-width: 800px;
    max-height: 80%;
    overflow-y: auto;
    overflow-x: hidden; /* Prevent horizontal scrolling */
    padding: 20px;
    border-radius: 8px;
    position: relative;
}


.closeButton {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
}

.closeButton:hover {
    color: var(--color-1); /* Light Blue */
}

@media (max-width: 768px) {
    .modal {
        width: 90%; /* 90% of screen width */
        padding: 8px;
    }
    .overlay {
        justify-content: initial;
    }
}