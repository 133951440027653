:root {
    --color-0: #226699;  /* Dark Blue */
    --color-1: #55ACEE;  /* Light Blue */
    --color-2: #EAAA00;  /* Dark Orange */
    --color-3: #FFCC00;  /* Light Orange */
    --color-4: #9933CC;  /* Dark Purple */
    --color-5: #CC66FF;  /* Light Purple */
    --color-6: #A0A0A0;  /* Gray */
    --color-7: #D3D3D3;  /* Light Gray */
    --color-8: #FFFFFF;  /* White */
}
