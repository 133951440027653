.authorListContainer {
    padding: 24px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
}

.createButton {
    background-color: #21808d;
    color: white;
    border: none;
    padding: 8px 16px;
    border-radius: 4px;
    cursor: pointer;
}

.createButton:hover {
    background-color: #1e6b78;
}

.authorTable {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 16px;
}

.authorTable th,
.authorTable td {
    padding: 8px 12px;
    border: 1px solid #e3e5dc;
    text-align: left;
}

.authorTable th {
    background-color: #f9faf7;
    font-weight: bold;
}

.authorTable img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    object-fit: cover;
}

.editButton,
.deleteButton {
    background-color: #f1f4f5;
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
}

.editButton:hover {
    background-color: #d3d8d9;
}

.deleteButton:hover {
    background-color: #d9534f;
    color: white;
}

.publishButton {
    background-color: #6c757d; /* A neutral blue-gray color */
    border: none;
    padding: 6px 12px;
    border-radius: 4px;
    cursor: pointer;
    margin-right: 8px;
    color: white;
}

.publishButton:hover {
    background-color: #5a6268;
}

.filterIconButton {
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    margin-left: 4px;
    vertical-align: middle;
}

.filterActive {
    color: #27ae60; /* Green when filtering is active */
    font-size: 20px;
}

.filterInactive {
    color: #6c757d; /* Neutral gray when filter is inactive */
    font-size: 20px;
}

.pagination {
    display: flex;
    justify-content: center;
    gap: 8px;
    align-items: center;
}

.pagination button {
    padding: 8px 12px;
    border: 1px solid #d3d8d9;
    background-color: white;
    cursor: pointer;
}

.pagination button:disabled {
    cursor: not-allowed;
    opacity: 0.5;
}
