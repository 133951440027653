/* src/components/authorPage/BookCarousel.module.css */
.carouselContainer {
    position: relative;
    width: 600px;
    min-height: 400px;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
}

.leftBook {
    position: absolute;
    width: 121px;
    height: 180px;
    left: 15%; /* approximate or tweak as needed */
    top: 110px;
    cursor: pointer;
    opacity: 0.4;
    filter: blur(1px);
    transition: all 0.3s ease;
}

.bookTitle {
    display: none;
}

.leftBook img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px; /* small rounding */
}

.rightBook {
    position: absolute;
    width: 125px;
    height: 180px;
    left: 65%;
    top: 110px;
    cursor: pointer;
    opacity: 0.4;
    filter: blur(1px);
    transition: all 0.3s ease;
}

.rightBook img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}


.centerBook {
    position: absolute;
    width: 148px;
    height: 221px;
    left: 50%;
    top: 90px;
    transform: translateX(-50%);
    cursor: pointer;
    box-shadow: 0 42px 79px -1.54545px rgba(26, 35, 39, 0.05),
    0 31px 43px -12.3636px rgba(26, 35, 39, 0.11);
    filter: drop-shadow(0px 6px 19px rgba(26, 35, 39, 0.06))
    drop-shadow(0px 3px 6px rgba(26, 35, 39, 0.06));
    border-radius: 8px;
    transition: all 0.3s ease;
}

.centerBook img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px;
}

.dotsContainer {
    position: absolute;
    left: 50%;
    top: 350px;
    transform: translateX(-50%);
    display: flex;
    flex-direction: row;
    gap: 7px;
    justify-content: center;
    align-items: center;
}

.dot,
.activeDot {
    width: 4px;
    height: 4px;
    border-radius: 50%;
    background: #1E3742;
    opacity: 0.2;
    cursor: pointer;
}
.activeDot {
    background: #21808D;
    opacity: 1;
}

.leftArrow,
.rightArrow {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background: none;
    border: none;
    font-size: 32px;
    color: #1E3742;
    opacity: 0.2;
    cursor: pointer;
    z-index: 999;
}

.leftArrow {
    left: 5%;
}
.rightArrow {
    right: 5%;
}

.leftArrow:hover,
.rightArrow:hover {
    opacity: 0.6;
}

@media (max-width: 768px) {
    .carouselContainer {
        margin-top: 0;
        /*justify-content: start;*/
        align-items: start;
        display: flex;
        flex-direction: column;
        /*border: solid red;*/
        min-height: initial;
        width: initial;
    }

    .leftBook,
    .rightBook,
    .dotsContainer {
        display: none; /* Hide side books and dots */
    }

    .centerBook {
        align-items: start;
        display: flex;
        flex-direction: row;
        position: initial;
        width: 250px; /* Responsive width (adjust as needed) */
        height: auto; /* Adjust height automatically */
        /*left: 50%;*/
        left: initial;
        top: initial;
        transform: initial;
        box-shadow: none;
    }

    .centerBook img {
        width: 80px;
        height: auto;
    }

    .leftArrow {
        display: none; /* Hide left arrow on small screens */
    }

    .rightArrow {
        position: initial;
        transform: initial;
        margin-top: 8px;
        margin-left: 30px;
        /*right: 5%;*/
        /*font-size: 24px; !* Optionally reduce arrow size *!*/
        opacity: 0.6; /* Make arrow more visible on small screens */
    }

    .bookTitle {
        margin-top: 8px;
        margin-left: 8px;
        display: inline;
    }
}